body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-form-large .ant-form-item .ant-form-item-label>label {
  font-size: 16px;
  height: 24px;
}

#PasswordResetModal .ms-modal__back {
  display: none;
}

#PasswordResetModal .ms-modal__figure {
  display: none;
}

.ant-form-item-label>label {
  height: auto !important;
}

.ant-input-affix-wrapper .ant-input-prefix {
  margin-inline-end: 8px !important;
}