.login {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
}

.login-form {
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 100vh;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.login-form-inner {
  width: 100%;
  max-width: 400px;
  background-color: #fff;
  border-radius: 5px;
}

.login-form-inner::-webkit-scrollbar {
  display: none;
}

.login-extra-content {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #E7E9F1;
  padding: 40px;

  @media (max-width: 800px) {
    display: none;
  }
}

.login-extra-content-container {
  width: 100%;
  max-width: 400px;
  align-items: flex-start;
}

.ant-typography .huge {
  font-size: 40px;
  font-weight: 600;
  line-height: 1.2;
  opacity: 0.5;
}